<template>
    <v-card>
      <v-card-text>
        
      <v-row>
        <!-- NEW VERSION -->
        <v-col cols="12" sm="12" md="12">
          <v-card color="rgba(0, 0, 0, 0.1)">
            <v-card-title class="text-h5 font-weight-bold" style="color:#385f73;">
              Datos Generales
            </v-card-title>
            <v-divider class="my-2"></v-divider>
            
            <v-card-text style="color:black">

              <v-row>
                <v-col cols="12" sm="6" md="3">
                  <span class="d-block font-weight-bold"
                    >Código del Cliente</span
                  >
                  {{ data.client_code }}
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <span class="d-block font-weight-bold"
                    >Nombres</span
                  >
                  {{ data.name }}
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <span class="d-block font-weight-bold"
                    >Apellidos</span
                  >
                  {{ data.surname }}
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <span class="d-block font-weight-bold"
                    >Fecha de primer pago</span
                  >
                  {{ dateFormat2(data.payment_date) }}
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <span class="d-block font-weight-bold"
                    >Vigencia (Desde)</span
                  >
                  {{ dateFormat2(data.validity_date1) }}
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <span class="d-block font-weight-bold"
                    >Vigencia (Hasta)</span
                  >
                  {{ dateFormat2(data.validity_date2) }}
                </v-col>
                
                <v-col cols="12" sm="6" md="3">
                  <span class="d-block font-weight-bold"
                    >Aseguradora</span
                  >
                  {{ data.insurance }}
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <span class="d-block font-weight-bold"
                    >Banco Endosado</span
                  >
                  {{ data.bank }}
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <span class="d-block font-weight-bold"
                    >Prima Neta</span
                  >
                  {{ data.initial_value }}
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <span class="d-block font-weight-bold"
                    >Prima Total</span
                  >
                  {{ data.total_value }}
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <span class="d-block font-weight-bold"
                    >Gastos de Emisión</span
                  >
                  {{ data.emision_cost }}
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <span class="d-block font-weight-bold"
                    >Moneda</span
                  >
                  {{ data.type_currency }}
                </v-col>
                
                <v-col cols="12" sm="6" md="3">
                  <span class="d-block font-weight-bold"
                    >Número de Pagos</span
                  >
                  {{ data.numbers_pays }}
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <span class="d-block font-weight-bold"
                    >Suma Asegurada</span
                  >
                  {{ data.amount_insured }}
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <span class="d-block font-weight-bold"
                    >Ramo</span
                  >
                  {{ data.ramo }}
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <span class="d-block font-weight-bold"
                    >Tipo</span
                  >
                  {{ data.branch }}
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <span class="d-block font-weight-bold"
                    >Fecha de Creación</span
                  >
                  {{ dateFormat1(data.date_created) }}
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <span class="d-block font-weight-bold"
                    >Ultima Modificación</span
                  >
                  {{ dateFormat1(data.update_at) }}
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <span class="d-block font-weight-bold"
                    >Creado Por:</span
                  >
                  {{ data.create_user }}
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <span class="d-block font-weight-bold"
                    >Modificado Por</span
                  >
                  {{ data.update_user }}
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <span class="d-block font-weight-bold"
                    >Observaciones</span
                  >
                  {{ data.comment }}
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-btn text>
              </v-btn>
            </v-card-actions>

          </v-card>
        </v-col>


        <v-col cols="12" sm="12" md="6" v-if="data.id">
          <v-card color="rgba(0, 0, 0, 0.1)">
            <v-card-title class="text-h5 font-weight-bold" style="color:rgb(31, 112, 135);">
              Documentos
            </v-card-title>

            <v-divider class="my-2"></v-divider>
             <v-card-text class="text--primary">
              <div
                    v-if="data.filename"
                    style="font-size:13px"
                    class="ml-2 ma-1"
                  >

                  <v-chip
                    :color=" $vuetify.theme.dark ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.1)' "
                    style="display:inline-block;"
                    @click="seeDocument(data.path)"
                    dark
                    :text-color=" $vuetify.theme.dark ? 'white' : 'black' "
                    >{{ data.filename }}
                  </v-chip>
              </div>
              <div
                    v-if="data.filename_request"
                    style="font-size:13px"
                    class="ml-2 ma-1"
                  >

                  <v-chip
                    :color=" $vuetify.theme.dark ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.1)' "
                    style="display:inline-block;"
                    @click="seeDocument(data.path_request)"
                    dark
                    :text-color=" $vuetify.theme.dark ? 'white' : 'black' "
                    >{{ data.filename_request }}
                  </v-chip>
              </div>
              <div
                    v-if="data.filename_policy"
                    style="font-size:13px"
                    class="ml-2 ma-1"
                  >

                  <v-chip
                    :color=" $vuetify.theme.dark ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.1)' "
                    style="display:inline-block;"
                    @click="seeDocument(data.path_policy)"
                    dark
                    :text-color=" $vuetify.theme.dark ? 'white' : 'black' "
                    >{{ data.filename_policy }}
                  </v-chip>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Documentos de cancelacion -->
        <v-col cols="12" sm="12" md="6" v-if="data.filename_letters">
          <v-card color="rgba(0, 0, 0, 0.1)">
            <v-card-title class="text-h5 font-weight-bold" style="color:rgb(31, 112, 135);">
              Documentos de Cancelación
            </v-card-title>

            <v-divider class="my-2"></v-divider>
             <v-card-text class="text--primary">
              <div
                    v-if="data.filename_letters"
                    style="font-size:13px"
                    class="ml-2 ma-1"
                  >

                  <v-chip
                    :color=" $vuetify.theme.dark ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.1)' "
                    style="display:inline-block;"
                    @click="seeDocument(data.path_letters)"
                    dark
                    :text-color=" $vuetify.theme.dark ? 'white' : 'black' "
                    >{{ data.filename_letters }}
                  </v-chip>
              </div>
              <div
                    v-if="data.filename_release"
                    style="font-size:13px"
                    class="ml-2 ma-1"
                  >

                  <v-chip
                    :color=" $vuetify.theme.dark ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.1)' "
                    style="display:inline-block;"
                    @click="seeDocument(data.path_release)"
                    dark
                    :text-color=" $vuetify.theme.dark ? 'white' : 'black' "
                    >{{ data.filename_release }}
                  </v-chip>
              </div>
              <div
                    v-if="data.filename_endorsement"
                    style="font-size:13px"
                    class="ml-2 ma-1"
                  >

                  <v-chip
                    :color=" $vuetify.theme.dark ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.1)' "
                    style="display:inline-block;"
                    @click="seeDocument(data.path_endorsement)"
                    dark
                    :text-color=" $vuetify.theme.dark ? 'white' : 'black' "
                    >{{ data.filename_endorsement }}
                  </v-chip>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

         <!-- ASEGURADOS -->
        <v-col cols="12" sm="12" md="6" v-if="insureds.length">
          <v-card color="rgba(0, 0, 0, 0.1)">
             <v-card-title class="text-h5 font-weight-bold" style="color:rgb(31, 112, 135);">
              Asegurados
            </v-card-title>
            <v-divider class="my-2"></v-divider>

            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Certificado
                      </th>
                      <th class="text-left">
                        Identidad
                      </th>
                      <th class="text-left">
                        Nombre
                      </th>
                      <th class="text-left">
                        Dependientes
                      </th>
                      <th class="text-left">
                        Fecha de Alta
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, key) in insureds"
                      :key="key"
                    >
                      <td>{{ item.certificate_number }}</td>
                      <td>{{ item.identity }}</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.dependents }}</td>
                      <td>{{ dateFormat2(item.date) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>

          </v-card>
        </v-col>

        <!-- Dependientes -->
        <v-col cols="12" sm="12" md="6" v-if="dependents.length">
          <v-card color="rgba(0, 0, 0, 0.1)">
             <v-card-title class="text-h5 font-weight-bold" style="color:rgb(31, 112, 135);">
              Dependientes
            </v-card-title>
            <v-divider class="my-2"></v-divider>

            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Asegurado
                      </th>
                      <th class="text-left">
                        Dependiente
                      </th>
                      <th class="text-left">
                        Parentezco
                      </th>
                      <th class="text-left">
                        Fecha de Nacimiento
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, key) in dependents"
                      :key="key"
                    >
                      <td>{{ item.policyholder }}</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.kinship }}</td>
                      <td>{{ dateFormat2(item.birthday) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>

          </v-card>
        </v-col>

        <!-- AUTOS -->
        <v-col cols="12" sm="12" md="6" v-if="cars.length">
          <v-card color="rgba(0, 0, 0, 0.1)">
             <v-card-title class="text-h5 font-weight-bold" style="color:rgb(31, 112, 135);">
              Autos
            </v-card-title>
            <v-divider class="my-2"></v-divider>

            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Marca
                      </th>
                      <th class="text-left">
                        Modelo
                      </th>
                      <th class="text-left">
                        Suma Asegurada
                      </th>
                      <th class="text-left">
                        Prima Neta
                      </th>
                      <th class="text-left">
                        Motor
                      </th>
                      <th class="text-left">
                        Placa
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, key) in cars"
                      :key="key"
                    >
                      <td>{{ item.brand }}</td>
                      <td>{{ item.model }}</td>
                      <td>{{ item.amount_insured }}</td>
                      <td>{{ item.initial_value }}</td>
                      <td>{{ item.engine }}</td>
                      <td>{{ item.plate }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>

          </v-card>
        </v-col>

        <v-col cols="12" sm="12" md="6" v-if="increments.length">
          <v-card color="rgba(0, 0, 0, 0.1)">
             <v-card-title class="text-h5 font-weight-bold" style="color:rgb(31, 112, 135)">
              Incrementos
            </v-card-title>
            <v-divider class="my-2"></v-divider>

            <v-card-text>
              <div
                  v-for="(element, key) in increments"
                  :key="key"
                  class="ml-2 ma-1"
                >
                
                <v-chip
                  color="rgba(0, 0, 0, 0.1)"
                  style="display:inline-block;"
                  class="mr-2"
                  >
                  {{ element.date }} 
                </v-chip>

                <v-chip
                  color="rgba(0, 0, 0, 0.1)"
                  style="display:inline-block;"
                  class="mr-2"
                  >
                  {{ element.amount_insured }} 
                </v-chip>
                
                <v-icon
                @click="seeDocument(element.path)"
                color="purple darken-1"
                >fas fa-file-pdf</v-icon>

              </div>
            </v-card-text>

          </v-card>
        </v-col>

        <v-col cols="12" sm="12" md="6" v-if="renovations.length">
          <v-card color="rgba(0, 0, 0, 0.1)">
             <v-card-title class="text-h5 font-weight-bold" style="color:rgb(31, 112, 135)">
              Renovaciones
            </v-card-title>
            <v-divider class="my-2"></v-divider>

            <v-card-text>
              <div
                  v-for="(element, key) in renovations"
                  :key="key"
                  class="ml-2 ma-1"
                >

                <v-chip
                  color="rgba(0, 0, 0, 0.1)"
                  style="display:inline-block;"
                  class="mr-2"
                  >
                  {{ validityFormat(element.validity_date1, element.validity_date2) }}
                </v-chip>
                
                <v-icon
                @click="seeDocument(element.path)"
                color="purple darken-1"
                >fas fa-file-pdf</v-icon>

              </div>
            </v-card-text>

          </v-card>
        </v-col>

        <!-- BIENES -->
        <v-col cols="12" sm="12" md="6" v-if="properties.length">
          <v-card color="rgba(0, 0, 0, 0.1)">
             <v-card-title class="text-h5 font-weight-bold" style="color:rgb(31, 112, 135);">
              Bienes
            </v-card-title>
            <v-divider class="my-2"></v-divider>

            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Tipo
                      </th>
                      <th class="text-left">
                        Suma Asegurada
                      </th>
                      <th class="text-left">
                        Dirección
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, key) in properties"
                      :key="key"
                    >
                      <td>{{ item.property }}</td>
                      <td>{{ numberWithCommas(item.amount_insured) }}</td>
                      <td>{{ item.location }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>

          </v-card>
        </v-col>

        <!-- COBRANZA -->
        <v-col cols="12" sm="12" md="12" v-if="collections.length">
          <v-card color="rgba(0, 0, 0, 0.1)">
             <v-card-title class="text-h5 font-weight-bold" style="color:rgb(31, 112, 135);">
              Cobranza
            </v-card-title>
            <v-divider class="my-2"></v-divider>

            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Cuota
                      </th>
                      <th class="text-left">
                        Valor
                      </th>
                      <th class="text-left">
                        Valor Pagado
                      </th>
                      <th class="text-left">
                        Saldo
                      </th>
                      <th class="text-left">
                        Fecha de Pago
                      </th>
                      <th class="text-left">
                        Dias de Mora
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, key) in collections"
                      :key="key"
                    >
                      <td>{{ item.number_quote }}</td>
                      <td>{{ numberWithCommas(item.calculated_amount) }}</td>
                      <td> <div v-if="item.payment_amount > 0"> {{ numberWithCommas(item.payment_amount) }} </div></td>
                      <td> <div v-if="item.payment_amount > 0">
                        {{ (parseFloat((item.payment_amount).replace(/,/g, "")) - parseFloat(item.calculated_amount)).toFixed(2) }}
                      </div> </td>
                      <td>{{ item.payment_date }}</td>
                      <td>{{ days_overdue(item) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>

          </v-card>
        </v-col>

      </v-row>
      </v-card-text>
    </v-card>

</template>

<script>
  import { mapActions, mapState } from "vuex";
  import axios from "axios";
  import * as moment from 'moment';

  export default {
    name: "reportPolicy",
    data: () => ({
      titulo: "Incrementos",
      policies: [],
      insureds: [],
      cars: [],
      increments: [],
      dependents: [],
      properties: [],
      renovations: [],
      collections: [],
      data: {},
      ramos: [
      { id: 1, name: "VIDA" },
      { id: 2, name: "DAÑOS" },
      { id: 3, name: "FIANZAS" },
      ],
      coins: [
        { id: 1, name: "Lempiras" },
        { id: 2, name: "Dólares" },
      ],
    }),
    
    methods: {
      ...mapActions(["cerrarSesion", "validateSession"]),

      async getData(){
        console.log(this.policy);
        this.data = {};
        this.insureds = [];
        this.cars = [];
        this.increments = [];
        this.properties = [];
        this.dependents = [];
        this.renovations = [];
        this.collections = [];
        if (!this.policy > 0) {
          return;
        }
        try {
          await axios.get(this.db + 'policy_report/' + this.policy,
            {
              headers: this.headers_db
            }).then(response => {
              this.data = response.data.data[0];
              
              
                if (this.data.id_ramo > 0) {
                  this.data.ramo = this.ramos.filter(doc => {
                    return doc.id == this.data.id_ramo
                  })[0].name;
                }
                if (this.data.type_currency > 0) {
                  this.data.type_currency = this.coins.filter(doc => {
                    return doc.id == this.data.type_currency
                  })[0].name;
                }
              

              this.loading = false;
              console.log(this.data);
            })
            .catch((error) => {
              console.log(error);
              this.validateSession(error).then((resp) => { if(resp) this.getData(); })
            });

            await axios.get(this.db + 'insureds/' + this.policy,
            {
              headers: this.headers_db
            }).then(response => {
              this.insureds = response.data.data;
            }).catch(console.log);

            await axios.get(this.db + 'cars/' + this.policy,
            {
              headers: this.headers_db
            }).then(response => {
              this.cars = response.data.data;
            }).catch(console.log);

            await axios.get(this.db + 'collections/quotes/' + this.policy,
            {
              headers: this.headers_db
            }).then(response => {
              this.collections = response.data.data;
              console.log(this.collections);
            }).catch(console.log);

            await axios.get(this.db + 'increment/policy/' + this.policy,
            {
              headers: this.headers_db
            }).then(response => {
              this.increments = response.data.data;
            }).catch(console.log);

            await axios.get(this.db + 'properties/policy/' + this.policy,
            {
              headers: this.headers_db
            }).then(response => {
              this.properties = response.data.data;
            }).catch(console.log);

            await axios.get(this.db + 'dependents/policy/' + this.policy,
            {
              headers: this.headers_db
            }).then(response => {
              this.dependents = response.data.data;
            }).catch(console.log);

            await axios.get(this.db + 'renovation/policy/' + this.policy,
            {
              headers: this.headers_db
            }).then(response => {
              this.renovations = response.data.data;
            }).catch(console.log);

        } catch (e) {
          console.log(e);
        }
      },

    async seeDocument(path){
      console.log(path);
      await axios.get(this.db + 'policy/download/' + path,
      {
        responseType: 'arraybuffer',
        headers: {
            // 'Content-Type': 'multipart/form-data',
            "Content-type": "application/x-www-form-urlencoded",
            'Authorization': 'BEARER ' + localStorage.token
        }
      })
      .then(response => {
          console.log(response );
          let blob = new Blob([response.data], { type: 'application/pdf' } ),
          url = window.URL.createObjectURL(blob)

          window.open(url);
      })
      .catch(error => {
          console.log("Fallo", error );
          if (error.response.status == 401) {
            this.getCustomers();
          }
      });
    },

    dateFormat1(date) {
      return moment(date).format('DD/MM/YYYY hh:mm a');
    },
    dateFormat2(date) {
      return moment(date).format('DD/MM/YYYY');
    },

    days_overdue(item) {
      let payment_amount = (item.payment_amount > 0) ? parseFloat(item.payment_amount) : 0;
      let calculated_amount = (item.calculated_amount > 0) ? parseFloat(item.calculated_amount) : 0;
      let balance = payment_amount - calculated_amount;
      let now = moment();
      
      if (balance < 0) {
        const daysPassed = now.diff(item.payment_date, 'days');
        return daysPassed;
      }
    },

    validityFormat(date1, date2) {
      date1 = moment(date1).format('DD-MM-YYYY');
      date2 = moment(date2).format('DD-MM-YYYY');
      return date1 + ' ~ ' + date2;
    },

    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    },
    computed: {
      ...mapState(["db","headers_db"]),
      cargando() {
        return this.carga ? "loading" : "false";
      }
    },

    mounted () {
        this.getData();
    },

    props: {
      policy: String
    }
  }
</script>

<style>
  .small {
    max-height: 350px;
    /* margin:  150px auto; */
  }
</style>